@import '../../style/vars.scss';

.featuresMenu {
    display: flex;
    font-size: 3rem;
    justify-content: space-between;
    align-items: center;
    max-width: 80rem;
    margin: 4rem auto;
    
    a {
        color: $maincol
    }
    
    @media (max-width: $break-small) {
        flex-direction: column;
        
        li {
            margin-bottom: 2rem
        }
    }
}

.multichannel {
    display: flex;
    width: 80%;
    max-width: 70rem;
    justify-content: space-between;
    padding-top: 3rem;

    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0;
    }

    i {
        font-size: 8rem
    }

    @media (max-width: $break-small) {
        display: block;

        li {
            margin-bottom: 3rem
        }
    }
}
