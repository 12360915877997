@import '../../style/vars.scss';

$footerFontCol: #bbb;

.foot {
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.75;
	background-color: #2c3e50;
	padding: 3rem 3rem 10rem 3rem;
	
	.mainWrp {
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		@media (max-width: $break-small) {
			flex-direction: column;
			
			.socials {
				margin-top: 2rem
			}
		}
	}
	
	p {
		margin: 0;
		color: #ffffff6e;
	}
	
	ul {
		list-style: none;
		margin-bottom: 1rem;
		
		li {
			display: inline-block;
			position: relative;
			
			&::after {
				background-color: $footerFontCol;
				content: "";
				position: absolute;
				left: 0;
				height: 57%;
				top: 25%;
				width: 1px;
			}
			
			&:first-child {
				&::after {
					content: none
				}
				a {
					padding-left: 0
				}
			}
			
			&:last-child {
				border: 0;
				a {
					padding-right: 0
				}
			}
		}
		a {
			color: $footerFontCol;
			padding: 0 1.6rem;
			font-size: 1.6rem;
		}
	}
	
	.socials li {
		border: 0;
		&::after {
			content: none
		}
		i {
			font-size: 2.1rem
		}
		a {
			padding: 0 1rem;
		}
	}
	
}