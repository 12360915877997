@import 'vars.scss';

#forbesleadinsights {
    .note {
        font-size: 1.1rem;
        background: $grey
    }
    
    .cols {
        display: flex;
        
        @media (max-width: $break-small) {
            flex-direction: column;
        }
        
        .col {
            width: 48%;
            max-width: 48rem
        }
    }
    
    .logoWrp {
        margin: 2rem 0;
        text-align: center;
        
        img {
            width: 23rem
        }
        
        div {
            border: 0;
            font-size: 1.5rem;
            padding-top: 0
        }
    }
    
    .mainWrp {
        .cols {
            width: 100%;
            align-items: flex-end;
            
            h2, h4 {
                text-align: left
            }
            h4 {
                font-weight: bold
            }
        }
        .col {
            display: block;
            
            img {
                width: 100%;
                height: auto;
            }
            
            @media (max-width: $break-small) {
                width: 100%;
                max-width: none
            }
        }
        
    }
}

#cloudexplain {
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: $break-small) {
        flex-direction: column;
        margin-bottom: 3rem
    }
    
    
    .bidi-arrows {
        max-width: 5rem;
        margin: 0 2rem;
        
        @media (max-width: $break-small) {
            transform: rotate(90deg);
            margin: 2rem 0;
        }
    }
    
    .computer {
        width: 27rem;
    }
    
    .cloud {
        width: 15rem
    }
    
    .array {
        width: 35rem;
    }
}

#cloudExplainWrp {
    h3 {
        font-size: 3rem;
        color: $maincol;
        margin: 2rem 0 5rem 0
    }
}

#specialColumnsWrp {
    h3 {
        margin-bottom: 4rem;
        span {
            color: $maincol;
            font-weight: bold;
        }
    }
    h4 {
        background: $grey;
        margin-bottom: 3rem;
        padding: .5rem 1rem;
    }
    .columnsofcolumns, .columnsWrp {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .columnofcolumn {
        width: 32%
    }
    
    .columnsWrp {
        .column {
            width: 48%;
            font-size: 1.3rem;
            max-width: 30rem
        }
        
        
        
        .imgWrp {
            margin-bottom: 2rem;
            display: flex;
            height: 7rem;
            justify-content: center;
            align-items: center;
        }
        
        img {
            width: 6rem
        }
    }
    
    @media (max-width: $break-small) {
        .columnsofcolumns {
            flex-direction: column;
        }
        
        .columnofcolumn {
            width: 100%;
            margin-bottom: 2rem
        }
    }
}