@import '../../style/vars';

.chatwrp {
	height: 100%;
	
	&.fullscreen {
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translateX(50%) translateY(-50%);
		width: 90%;
		height: 90%;
	}
	
	 iframe {
		 width: 100%;
		 min-height: 500px;
		 height: 100%; /* removed vh as it was going below the address bar on mobile */
		 display: block;
	 }
	
	@media (max-width: $break-small) {
		.iframeWrp {
			padding: 4rem 0;
		}
		/*
		questo serviva per andare fullheight su mobile, ma rompe la nuova home,
		da rimettere se servira' un altra chat a giro in mezzo alla pagina
		iframe {
			height: calc(100vh - 105px);
		}*/
	}
 }
