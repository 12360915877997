@import '../../style/vars.scss';
.expandButt {
    position: absolute;
    right: 0;
    top: 1.1rem;
    display: flex;
    
    i {
        margin: 0
    }
    
    span {
        display: none;
        margin-left: .5rem;
    }
    
    &:hover span {
        display: block;
    }
}
