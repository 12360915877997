@import '../../style/vars.scss';

.headMenu {
	background-color: rgba(255,255,255,.95);
	position: fixed;
	left: 0;
	right: 0;
	padding: 1.5rem 2.4rem 0.75rem 2.4rem;
	width: 100%;
	z-index: 1;
	backdrop-filter: blur(3px);
	
	.logoChatbotStudio {
		div {
			letter-spacing: 6px;
		}
	}
	
	.mainWrp {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.logo {
		text-align: center;
		img {
			height: 5.3rem;
			display: block;
			margin-bottom: .2rem
		}
		div {
			font-size: 1.1rem;
			color: $maincol
		}
	}
	
	.accessButt {
		a {
			border-radius: 2.5rem;
			
			@media (max-width: $break-small) {
				border-radius: 0
			}
		}
		
		&.register a {
			margin: 0 1.5rem;
			color: #fff !important;
			background: $maincol;
			&:hover {
				background: lighten($maincol, 10%)
			}
			
			@media (max-width: $break-small) {
				margin: 0
			}
		}
		
		&.login {
			
			a {
				border: 1px solid #ccc;
				&:hover {
					border-color: darken(#ccc, 10%);
				}
				
				@media (max-width: $break-small) {
					border-right: 0;
					border-left: 0
				}
			}
			
		}
		
	}
	
	.navStyle {
		
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		
		ul {
			margin: 0;
			
			li.hovered ul {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
			
			ul {
				opacity: 0;
				position: absolute;
				background: #fff;
				padding: .5rem;
				//border: 1px solid #ccc;
				border-top: 0;
				box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
				transition: all .3s;
				visibility: hidden;
				transform: translateY(-2rem);
				z-index:1;
				
				li {
					display: block;
					
					i {
						width: 1.5rem;
						text-align: center;
						margin-right: 1rem
					}
				}
			}
		}
		li {
			display: inline-block;
			
			&:hover {
				cursor: default;
			}
			a {
				display: block;
				line-height: 2.2rem;
				font-size: 1.6rem;
				border-bottom: 1px solid transparent;
				padding: .4rem 1.5rem;
				position: relative;
				
				@media (max-width: $break-small) {
					display: flex;
					justify-content: space-between;
					width: 100%;
					padding: 1.2rem 2rem;
					border-bottom: 1px solid #ccc;
				}
				
				&.sel, &:hover {
					color: $mainFontCol;
					border-bottom: 1px solid $mainFontCol;
				}
				&:hover {
					color: $mainFontCol;
					border-bottom: 1px solid #ccc;
				}
			}
		}
		
		@media (max-width: $break-small) {
			display: none;
			position: absolute;
			top: 6.5rem;
			left: 0;
			width: 100%;
			height: calc(100vh - 64.5px);
			overflow: auto;
			background: rgba(255,255,255,.9);
			animation: fadeInDown .5s;
			z-index: 999;
			
			@keyframes fadeInDown {
				from {
					opacity: 0;
					transform: translate3d(0, -100%, 0);
				}
				
				to {
					opacity: 1;
					transform: none;
				}
			}
			
			.fadeInDown {
				animation-name: fadeInDown;
			}
			
			ul {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				flex-direction: column;
				
				li ul {
					transition: none;
					background: #f6f6f6;
				}
				li.hovered ul {
					position: static !important;
				}
			}
			
			li {
				text-align: center;
				width: 100%
			}
			
			a {
				font-size: 3rem;
			}
		}
	}
	
}

:global(.openMenu) {
	@media (max-width: $break-small) {
		.navStyle {
			display: block !important;
		}
	}
}
