@import 'vars.scss';

.forbes {
    
    .full {
        //background: #7dc0ea
    }
    
    #forbesChat {
        width: 100%;
        max-width: 110rem;
        height: 60rem
    }
    .logoWrp {
        
        img {
            max-width: 100%;
            width: 400px;
        }
        
        div {
            border-top:1px solid #ccc;
            padding-top: 1rem;
            font-size: 2.2rem
        }
        
        margin: 6rem 0 12rem 0
    }
    .imgHolder {
        margin-left: 0;
        width: auto;
    }
    ul {
        list-style: circle;
        padding-left: 2rem;
    }
    .changecol {
        color: #94c8f4;
        font-size: inherit;
    }
    .cols {
        h3 {
            text-align: left;
            display: flex;
        }
        //display: block;
        justify-content: space-between;
        align-items: flex-start;
        
        img {
            height: 3rem;
            margin: 0 1rem 1rem 0!important
        }
        
        &>.col {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    .col {
        width: 50%;
        margin-left: 1.5rem;
    }
    .col:first-child {
        margin-left: 0
    }
    
    
    @media (max-width: $break-small) {
        .cols>.col {
            width: 100%;
            margin-left: 2rem;
        }
    }
    
    @media (max-width: $break-micro) {
        .cols>.col {
            display: block;
            margin: 0;
            padding: 0 2rem;
        }
        .cols .col .col {
            width: 100%;
            margin: 0 0 4rem 0;
        }
    }
}